.wrapper{
    max-width: 500px;
    width: 100%;
    background: #fff;
    margin: 20px auto;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.125);
    padding: 30px;
  }
  
  .wrapper .title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
    color: #fec107;
    text-transform: uppercase;
    text-align: center;
  }
  
  .wrapper {
    width: 100%;
  }
  
  .wrapper  .inputfield{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .wrapper  .inputfield label{
     width: 200px;
     color: #757575;
     margin-right: 10px;
    font-size: 14px;
  }
  
  .wrapper  .inputfield .input,
  .wrapper  .inputfield .textarea{
    width: 100%;
    outline: none;
    border: 1px solid #d5dbd9;
    font-size: 15px;
    padding: 8px 10px;
    border-radius: 3px;
    transition: all 0.3s ease;
  }
  
  .wrapper  .inputfield .textarea{
    width: 100%;
    height: 125px;
    resize: none;
  }
  
  .wrapper  .inputfield .custom_select{
    position: relative;
    width: 100%;
    height: 37px;
  }
  
  .wrapper  .inputfield .custom_select:before{
    content: "";
    position: absolute;
    top: 12px;
    right: 10px;
    border: 8px solid;
    border-color: #d5dbd9 transparent transparent transparent;
    pointer-events: none;
  }
  
  .wrapper  .inputfield .custom_select select{
    -webkit-appearance: none;
    -moz-appearance:   none;
    appearance:        none;
    outline: none;
    width: 100%;
    height: 100%;
    border: 0px;
    padding: 8px 10px;
    font-size: 15px;
    border: 1px solid #d5dbd9;
    border-radius: 3px;
  }
  
  
  .wrapper  .inputfield .input:focus,
  .wrapper  .inputfield .textarea:focus,
  .wrapper  .inputfield .custom_select select:focus{
    border: 1px solid #fec107;
  }
  
  .wrapper  .inputfield p{
     font-size: 14px;
     color: #757575;
  }
  .wrapper  .inputfield .check{
    width: 15px;
    height: 15px;
    position: relative;
    display: block;
    cursor: pointer;
  }
  .wrapper  .inputfield .check input[type="checkbox"]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .wrapper  .inputfield .check .checkmark{
    width: 15px;
    height: 15px;
    border: 1px solid #fec107;
    display: block;
    position: relative;
  }
  .wrapper  .inputfield .check .checkmark:before{
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 5px;
    height: 2px;
    border: 2px solid;
    border-color: transparent transparent #fff #fff;
    transform: rotate(-45deg);
    display: none;
  }
  .wrapper  .inputfield .check input[type="checkbox"]:checked ~ .checkmark{
    background: #fec107;
  }
  
  .wrapper  .inputfield .check input[type="checkbox"]:checked ~ .checkmark:before{
    display: block;
  }
  
  .wrapper  .inputfield .btn{
    width: 100%;
     padding: 8px 10px;
    font-size: 15px; 
    border: 0px;
    background:  #fec107;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    outline: none;
  }
  

  .wrapper  .inputfield .btn:hover:disabled , .wrapper  .inputfield .btn:disabled {
    background:  #c9be9f;
    color: #fff;
    outline: black;
  }

  .wrapper  .inputfield .btn:hover{
    background: #ffd658;
  }

  .wrapper  .inputfield:last-child{
    margin-bottom: 0;
  }
  
  @media (max-width:420px) {
    .wrapper  .inputfield{
      flex-direction: column;
      align-items: flex-start;
    }
    .wrapper  .inputfield label{
      margin-bottom: 5px;
    }
    .wrapper  .inputfield.terms{
      flex-direction: row;
    }
  }