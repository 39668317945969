html, body {
  margin: 0px;
  padding: 0px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.item-list{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch; 
  border-color: green;
  border-style: dashed;
}
.item-list-item{
  width: 100px;  

  border-color: blue;
  border-style: dashed;
}

.pos-view{
  display: flex;
 
}
