td, th {
    border: 1px solid #999;
    padding: 0.5rem;
  }

.shoping-cart{
    border-collapse:collapse;
    width: 100%;

}
.cmpboarder{

    border-color: rgb(228, 18, 18);
    border-style: dashed;
}
  
td{
overflow-x: auto;
border-color: blue;
border-style: dashed;
word-break: break-all;
overflow: hidden;
}

  tr:nth-child(even) {
    background-color: #faffb0;
  }

  
  th {
    background: rgb(97, 95, 221);
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }